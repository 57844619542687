import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TextAndColorExample from '../../../../components/examples/TextAndColorExample';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/colour/tokens",
  "title": "Colour tokens - Tokens"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tokens`}<a parentName="h2" {...{
        "href": "#tokens",
        "aria-label": "tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HEX value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-brick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-brick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#bd2719`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-brick)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-bus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-bus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#0000bf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-bus)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-coat-of-arms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-coat-of-arms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#0072c6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-coat-of-arms)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-gold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-gold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#c2a251`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-gold)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-silver`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-silver`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#dedfe1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-silver)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-copper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-copper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#00d7a7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-copper)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-engel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-engel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffe977`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-engel)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-fog`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-fog`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#9fc9eb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-fog)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-metro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-metro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fd4f00`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-metro)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-summer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-summer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffc61e`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-summer)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-suomenlinna`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-suomenlinna`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f5a3c7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-suomenlinna)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-tram`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-tram`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#008741`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-tram)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Brand colour tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HEX value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-brick-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-brick-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#800e04`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-brick-dark)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-brick-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-brick-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#facbc8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-brick-medium-light)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-brick-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-brick-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffeeed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-brick-light)" name="Brick example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-bus-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-bus-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#00005e`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-bus-dark)" name="Bus example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-bus-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-bus-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ccccff`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-bus-medium-light)" name="Bus example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-bus-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-bus-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f0f0ff`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-bus-light)" name="Bus example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-coat-of-arms-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-coat-of-arms-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#005799`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-coat-of-arms-dark)" name="Coat of Arms example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-coat-of-arms-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-coat-of-arms-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#b5daf7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-coat-of-arms-medium-light)" name="Coat of Arms example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-coat-of-arms-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-coat-of-arms-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e6f4ff`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-coat-of-arms-light)" name="Coat of Arms example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-gold-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-gold-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#9e823c`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-gold-dark)" name="Gold example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-gold-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-gold-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e8d7a7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-gold-medium-light)" name="Gold example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-gold-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-gold-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f7f2e4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-gold-light)" name="Gold example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-silver-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-silver-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#b0b8bf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-silver-dark)" name="Silver example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-silver-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-silver-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#efeff0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-silver-medium-light)" name="Silver example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-silver-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-silver-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f7f7f8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-silver-light)" name="Silver example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-copper-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-copper-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#00a17d`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-copper-dark)" name="Copper example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-copper-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-copper-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#9ef0de`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-copper-medium-light)" name="Copper example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-copper-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-copper-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#cffaf1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-copper-light)" name="Copper example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-engel-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-engel-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#dbc030`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-engel-dark)" name="Engel example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-engel-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-engel-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff3b8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-engel-medium-light)" name="Engel example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-engel-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-engel-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff9db`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-engel-light)" name="Engel example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-fog-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-fog-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#72a5cf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-fog-dark)" name="Fog example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-fog-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-fog-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#d0e6f7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-fog-medium-light)" name="Fog example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-fog-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-fog-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e8f3fc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-fog-light)" name="Fog example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-metro-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-metro-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#bd2f00`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-metro-dark)" name="Metro example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-metro-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-metro-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffcab3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-metro-medium-light)" name="Metro example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-metro-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-metro-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffeee6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-metro-light)" name="Metro example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-summer-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-summer-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#cc9200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-summer-dark)" name="Summer example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-summer-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-summer-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffe49c`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-summer-medium-light)" name="Summer example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-summer-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-summer-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff4d4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-summer-light)" name="Summer example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-suomenlinna-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-suomenlinna-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e673a5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-suomenlinna-dark)" name="Suomenlinna example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-suomenlinna-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-suomenlinna-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffdbeb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-suomenlinna-medium-light)" name="Suomenlinna example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-suomenlinna-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-suomenlinna-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff0f7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-suomenlinna-light)" name="Suomenlinna example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-tram-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-tram-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#006631`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-tram-dark)" name="Tram example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-tram-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-tram-medium-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#a3e3c2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-tram-medium-light)" name="Tram example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-tram-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-tram-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#dff7eb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-tram-light)" name="Tram example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Brand colour variation tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HEX value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#0062b9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-info)" name="Info example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-info-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-info-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#004f94`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-info-dark)" name="Info dark variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-info-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-info-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e5eff8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-info-light)" name="Info light variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-success`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-success`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#007a64`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-success)" name="Success example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-success-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-success-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#006250`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-success-dark)" name="Success dark variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-success-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-success-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e2f5f3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-success-light)" name="Success light variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-alert`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-alert`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffda07`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-alert)" name="Alert example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-alert-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-alert-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#d18200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-alert-dark)" name="Alert dark variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-alert-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-alert-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff4b4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-alert-light)" name="Alert light variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#b01038`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-error)" name="Error example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-error-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-error-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#8d0d2d`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-error-dark)" name="Error dark variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-error-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-error-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f6e2e6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-error-light)" name="Error light variant example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Brand colour tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HEX value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#000000`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black)" name="Black example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#1a1a1a`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-90)" name="Black-90 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-80`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-80`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#333333`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-80)" name="Black-80 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-70`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-70`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#4d4d4d`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-70)" name="Black-70 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-60`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-60`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#666666`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-60)" name="Black-60 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-50`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-50`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#808080`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-50)" name="Black-50 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-40`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-40`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#999999`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-40)" name="Black-40 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-30`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-30`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#b3b3b3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-30)" name="Black-30 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#cccccc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-20)" name="Black-20 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#e6e6e6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-10)" name="Black-10 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-black-5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#f2f2f2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-black-5)" name="Black-5 example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#ffffff`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><TextAndColorExample color="var(--color-white)" name="White example" mdxType="TextAndColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Grayscale tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      